/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// rails-ujs through webpack
import Rails from 'rails-ujs'

// stimulus, starts inside
import "controllers"

// all things css
import "stylesheets"

// JS - jquery
import 'jquery'

// JS - bootstrap and friends
import 'bootstrap'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.cs.min.js';
import 'select2'

// local listpress because webpacker stinks
import 'listpress'

// global non-stimulus stuff
import 'global'

// check for manual spot price change
import "spot_price_change"

// screen select screen
import 'screen_select'
import 'dropdown_check'
import 'day_highlight'
import 'occupancies'
import 'resizable_listing'
import 'show_campaign'
import 'export_screens'

window.jQuery = $;

Rails.start();
